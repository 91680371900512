.circleReconInsights{
.circle {
    background-color: rgba(231, 36, 36, 0.02);
    // border-radius: 100px;
    flex-shrink: 0;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin: auto;
    // width: 68px;
    // height: 68px;
    padding: 20px;
    box-sizing: content-box !important;

    // background: #531c4c !important;
    // color: wheat !important;

    // border-radius: 150px;
    // background: #e0e0e0;
    // box-shadow: 16px 16px 31px #c3c3c3, -16px -16px 31px #fdfdfd;

    // color: black;
    font-weight: 900;


    border-radius: 10px;
    background: #531c4c;
    color: wheat;
    // width: 170px;
    height: 4px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}