.rules_window{
    display: inline-block;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: column !important;
}
.sanitizer_popup
{   z-index: 999 !important;
    .MuiDialog-paperFullScreen {
    max-width: 1050px;
    top: 70px;
    left: 100px;
    z-index: 100 !important;
    padding-bottom: 100px;
    }
}