.business_tabs{
    width: 100%;
    div#addbusinesspartner h2 {
        text-align: center;
        font-weight: 700;
    }
    .MuiTabs-flexContainer{
        width: 100%;
        button{
            padding: 10px;
            width: 50%;
            text-align: center;
        }
    }
    
}
.businesspartner_btn
{
    min-height: 50px !important;
    padding: 0 15px !important;
    border: none;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.businessmodal_footer{
    margin-top:20px;
    align-items: center !important;
}

.businesspartner_popup {
    z-index: 999 !important;
    top: 60px !important;
    left: 250px !important;
}
.applicableTDSPercentages{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.business_textarea {
    height: 50px !important;
    padding: 10px;
    border-radius: 4px;
}
.dropdown_other_highlight{
    border-top: 2px solid gray !important;
}
.errorBulkUploadMsg{
    font-size: 18px !important;
}
.errorBulkUploadTitle{
    font-weight: 600 !important;
    margin-bottom: 15px;
}