.d_flex {
    display: flex;
}

.textfeild_width {
    width: 60%;
}

.designationSetting {
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 100%;
    }
}

.addBpUser_label {
    font-size: 18px;
    font-family: 'Roboto';
}
.editBpContactMaster_popup {
    z-index: 999 !important;
    // top: 60px !important;
    left: 150px !important;
}
.editBpContactMaster_footer{
    margin-top:20px;
    align-items: center !important;
}
.editBpContactMaster_footer_btn{
    min-height: 50px !important;
    padding: 0 15px !important;
    border: none;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.table_container{
    max-width: fit-content;
    max-height: fit-content;
}
.table_fixed{
    overflow-x: scroll;
    overflow-y: scroll;
    height: fit-content;
    max-height: 50vh;
    
    table th {
        position: sticky;
        top: 0px;
        z-index: 2;
    }
}
.replyToEmailAddress{
    .MuiAutocomplete-clearIndicator {
        display: none;
    }
}