.partnerUseCaseMasterFilter{
    .MuiDialog-paper{
        max-width: 750px !important;
        width: 750px !important;
    }
    .category_textField_width_390{
        .MuiTextField-root{
            width: 390px !important;
        }
    }
    .textField_width_185{
        .MuiTextField-root{
            width: 185px !important;
        }
    }
}