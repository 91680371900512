.jodit-wysiwyg {
  padding-left: 30px !important;
  ol {
    padding-left: 50px;
  }
  ul {
    padding-left: 50px;
  }
}
.jodit-status-bar-link {
  display: none;
}

.jodit-container:not(.jodit_fullsize) {
  max-height: 300px;

  .jodit-workplace {
    max-height: 213px !important;
    overflow: scroll;
  }
}
