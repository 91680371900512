.algo_section_heading{
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 22px;
    font-weight: 900;
    text-decoration: underline;
}
.recon_segment {
    margin-top: 25px;
    border: 1px solid black;
    padding-left: 10px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 15px;
    cursor: pointer;
    background: #531c4c;
    color: white;
  }