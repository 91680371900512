.matchId_heading {
  background: #fafafa;
  padding: 8px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.changes_done_heading {
  padding: 20px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  // text-decoration: underline;
  border-bottom: 1px solid #aaa;
}
.changes_done_entries_reconcile {
  background: #27b27c;
  border-radius: 10px;
  margin-top: 4px;
  margin-left: 0 !important;
}
.changes_done_entries_unReconcile {
  background: #eb4d47;
  border-radius: 10px;
  margin-top: 4px;
  margin-left: 0 !important;
}
.changes_done_total {
  background-color: wheat;
  border-radius: 10px;
  // margin-top: 4px;
  // margin-left: 0 !important;
  cursor: unset !important;
}

.apply_changes {
  margin: 15px;
  text-align: center;
  font-size: 16px;
}
.apply_changes_text {
  background: gray !important;
  border-radius: 20px !important;
  color: white !important;
  padding: 8px !important;
  text-transform: none !important;
}
.borderLeft {
  border-left: 1px solid #e8e8f0;
}

.manual_box_container {
  .changes_done {
    min-width: 250px;
    position: absolute;
    right: 0;
    overflow: visible !important;
    transform: translateX(calc(100% + 10px));
    transition: all 0.25s ease-out;
    z-index: 10;

    &.active {
      transform: translateX(0);
    }

    .open_changes_icon {
      position: absolute;
      top: 2px;
      left: -30px;
      background: #fff;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      padding-left: 6px;
      transition: all 0.5s ease;
    }
  }
}

.filter_icon {
  svg {
    fill: #27b27c;
  }
}
