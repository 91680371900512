/* ===== variables for color theme =======      */

$primary-color: #531c4c;
$text-color: #808080;
$background-color: #531c4c;
$secondary-color: #27b27c;
* {
  margin: 0px;
  padding: 0px;
  font-family: "WorkSans";
  font-size: 14px;
}
input,
select {
  outline: none;
}
.d_flex {
  display: flex;
}
.d_block {
  display: block !important;
}
.center_align {
  display: flex;
  justify-content: center;
}
.center_align_ver_horiz {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical_center_align {
  display: flex !important;
  align-items: center !important;
}
.right_align {
  display: flex;
  justify-content: right;
}
.left_align {
  display: flex;
  justify-content: left;
}
.flex_end {
  display: flex !important;
  justify-content: flex-end !important;
}
.flex_start {
  display: flex !important;
  justify-content: flex-start !important;
}
.space_around {
  display: flex !important;
  justify-content: space-around !important;
}
.space_between {
  display: flex !important;
  justify-content: space-between !important;
}
.space_evenly {
  display: flex !important;
  justify-content: space-evenly !important;
}
.text_center {
  text-align: center !important;
}
.cursor_pointer {
  cursor: pointer;
}
.center-cell > .ag-cell-wrapper {
  justify-content: center;
}
.layout_wrapper {
  height: 100%;
  display: flex;
}
.layout_content {
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-width: 0px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.layout-vertical-nav {
  flex: 0 0 auto;
  overflow-x: hidden;
  // transition: width 0.25s ease-in-out 0s;
}
.main-rightbar {
  flex-grow: 1;
  width: 100%;
  padding: 1.5rem;
  // transition: padding 0.25s ease-in-out 0s;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background-color: #f0f0f7;
  padding-top: 80px;
  .MuiCard-root {
    border-radius: 8px;
  }
}
.navbar-item,
.navbar-link {
  padding: 0 !important;
  margin: 0 !important;
}
.isSubTab {
  color: white !important;
  padding: 5px 5px !important;
  cursor: pointer;
}
.themhead,
.MuiDrawer-paper {
  background-color: $background-color !important;
}
.menu_open {
  svg {
    font-size: 1.5rem !important;
  }
}
.menu_close {
  svg {
    font-size: 2rem !important;
  }
}

.alert-enter {
  width: calc(49px);
  // transform: scale(0.9);
}

.alert-enter-active {
  width: 175px;
  transform: translateX(0);
  transition: width 300ms, transform 300ms;
  z-index: 9999;
}
.alert-exit {
  width: 180px;
}
.alert-exit-active {
  width: calc(49px);
  transition: width 300ms, transform 300ms;
}

.menu_drawer {
  // transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  & > div {
    z-index: 9999 !important;
  }
}
.logo {
  width: 100%;
  text-align: center;
  .navbar-item {
    padding-left: 0 !important;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
      max-width: 105px;
      min-width: 105px;
    }
  }
}
.navbar-item {
  color: white !important;
  padding: 5px 5px !important;
  img {
    outline: none;
  }
  &:hover {
    background: none;
  }
}
.leftNavbar {
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
}
.menu_item {
  padding-left: 2px !important;
  padding-right: 0px !important;
  & > div {
    margin-right: 8px !important;
    & > svg {
      color: white;
      font-size: 25px;
    }
  }
}
.menu_item_open {
  padding-left: 10px !important;
  padding-right: 0px !important;
  & > div {
    margin-right: 8px !important;
    & > svg {
      color: white;
      font-size: 25px;
    }
  }
}
.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
.navbar-item.is-active,
.navbar-item:focus,
.navbar-item:focus-within,
.navbar-item:hover {
  color: $secondary-color !important;
  background: transparent;
  box-shadow: none;
}

.resetpass_wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  & > div {
    width: 40vw;
    margin-top: 2vw;
    text-align: center;
  }
}

.theme_btn {
  background-color: $secondary-color !important;
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  font-size: 16px !important;
  text-transform: none !important;
  color: white !important;
  cursor: pointer;
  a {
    color: white !important;
  }

  &:disabled {
    background-color: #85d1b4 !important;
  }
}
.theme_btn_warning{
  background-color: #eb4d47 !important;
  &:disabled {
    background-color: #eb4d47 !important;
  }
}
.theme_outline_btn {
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  font-size: 16px !important;
  text-transform: none !important;
  background-color: #fff !important;
  color: #363636 !important;
  cursor: pointer;
}
.box_head {
  label {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0 !important;
  }
}

.modal-card {
  max-height: calc(100vh - 150px);
}
.navbar-item.this-page {
  height: auto;
  font-size: 16px;
  line-height: 21px;
  color: white;
  background: #27b27c !important;
  width: auto;
  padding: 7px 4px !important;
  border-radius: 3px;
  min-width: 120px;
}
.navbar-item.this-page:hover,
.navbar-item.this-page:focus {
  color: white !important;
}
.logout_menu {
  .MuiMenu-paper {
    top: 56px !important;
    left: initial !important;
    right: 38px !important;
  }
}
.select_ui {
  padding: 10px;
  margin-right: 30px;
  border-radius: 6px;
  border-color: gray;
  min-height: 50px;
}

/*=============== css for inline removal =========================*/
.table_list {
  // padding-bottom: 40px;
  max-width: 1400px;
  margin-inline: auto;

  .userlisttable {
    padding-right: 0;
    padding-left: 0;
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }
}
.benificiary_wrapper {
  margin: 25px 0;
  display: inline-flex;
  justify-content: center;
  width: 100%;
}

.account_center_align {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.coulmn_grid {
  padding-top: 50px;
  flex-flow: column;
  margin: 0 auto;
  flex-direction: column !important;
}

.account_headings {
  padding-bottom: 40px;
  margin: 0 auto;
  max-width: 900px;
  h3 {
    font-size: 20px;
    strong {
      font-size: 20px;
    }
  }
}

.benificiary_box {
  border: 1px solid #ccc;
  max-width: 800px;
  min-width: 612px;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 2px 10px 0px #ccc;
  background: white;
  h1 {
    font-size: 20px;
    text-align: center;
    padding: 30px 0;
  }
}
.account_table_row {
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
.account_table_column {
  padding-right: 0;
  padding-left: 0;
  // margin: 25px 0;
  display: inline-flex;
  justify-content: center;
  width: 100%;
}
.box_head {
  text-align: center;
  margin-top: 40px;
  font-size: 20px;
  font-weight: 900;
}

/* ===== width =======      */
.min_width_300 {
  min-width: 300px !important;
  text-transform: none !important;
  // color: black !important;
}

.width_180 {
  width: 180px;
}
.width_150 {
  width: 150px;
}
.width_250 {
  width: 250px;
}

/* ===== padding =======      */
.pt_10 {
  padding-top: 10px;
}
.pt_5 {
  padding-top: 5px;
}
.pt_15 {
  padding-top: 15px;
}
.pb_5 {
  padding-bottom: 5px;
}
.pb_10 {
  padding-bottom: 10px;
}
.pl_10 {
  padding-left: 10px;
}
.mt_10 {
  margin-top: 10px !important;
}
.mt_15 {
  margin-top: 15px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_25 {
  margin-top: 20px;
}
.mt_30 {
  margin-top: 30px;
}
.mt_40 {
  margin-top: 40px;
}
.mb_25 {
  margin-bottom: 25px;
}
.mb_35 {
  margin-bottom: 35px;
}
.mb_15 {
  margin-bottom: 15px;
}
.pl_50 {
  padding-left: 50px;
}
.mt_30 {
  margin-top: 30px !important;
}
.mr_10 {
  margin-right: 10px !important;
}
.mr_20 {
  margin-right: 20px !important;
}
.p_10 {
  padding: 10px;
}
.p_20 {
  padding: 20px;
}
.p_40 {
  padding: 40px !important;
}
.ml_10 {
  margin-left: 10px !important;
}
.ml_20 {
  margin-left: 20px !important;
}
.ml_30 {
  margin-left: 30px !important;
}
.ml_60 {
  margin-left: 60px;
}
.m_15 {
  margin: 15px;
}
.m_20 {
  margin: 20px !important;
}
.m_40 {
  margin: 40px !important;
}
.mb_80 {
  margin-bottom: 80px;
}
.mb_20 {
  margin-bottom: 20px;
}
/*=============== ag grid table css =========================*/
.ag-header-cell {
  background-color: #531c4c !important;
  .ag-header-cell-text,
  .ag-icon {
    color: white !important;
  }
}
.tabs.is-toggle li.is-active a {
  background-color: #27b27c;
  border-color: #27b27c;
  color: #fff;
}
.ag-root.ag-layout-normal,
.ag-root.ag-layout-auto-height {
  overflow-x: scroll !important;
}
.ag-theme-alpine {
  width: 100%;
}

/*=============== color =========================*/
.white_color {
  color: #ffffff !important;
}

.fw_900 {
  font-weight: 900 !important;
}
.fw_600 {
  font-weight: 600 !important;
}

.fs_24 {
  font-size: 24px !important;
}
.fs_16 {
  font-size: 16px !important;
}
.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px !important;
}
.fs_12 {
  font-size: 12px !important;
}
.fs_14 {
  font-size: 14px !important;
}
.theme_btn_disable {
  background-color: $secondary-color !important;
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  font-size: 16px !important;
  text-transform: none !important;
  color: white !important;
  opacity: 0.6;
}
.dialog_header {
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dialog_footer {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
  background-color: #f5f5f5;
}

.err_text {
  font-size: 14px;
  color: red;
}

.h_40 {
  height: 40px;
}

.w_200 {
  width: 200px !important;
}
.w_250 {
  width: 250px !important;
}
.w_160 {
  width: 160px !important;
}
.w_300 {
  width: 300px !important;
}
.w_350 {
  width: 350px !important;
}
.w_400 {
  width: 400px !important;
}

.mw_250 {
  min-width: 250px !important;
}

.w_50_per {
  width: 50%;
}
.w_100_per {
  width: 100%;
}
.uploadSection {
  height: 200px;
  background: #f5f5f5;
  border: 2px solid #27b27c;
  border-style: dashed;
  border-radius: 20px;
}

.textField_height {
  div {
    height: 30px !important;
  }

  & [data-monetary-input=true] {
    & label[data-shrink=false] {
      top: -13px;
    }

    & div {
      height: unset !important;
    }

    & input{
      padding-top: 3.5px;
      padding-bottom: 3.5px;
    }
  }
}

fieldset legend span {
  font-size: 11px;
}
.category_ui {
  .MuiChip-root {
    height: 20px !important;
    max-width: 120px !important;
    border-radius: 12px;
    margin-top: 4px !important;
  }
  .MuiChip-deleteIcon {
    font-size: 15px !important;
  }
  .MuiAutocomplete-hasPopupIcon {
    padding: 0px !important;
  }
  .MuiAutocomplete-hasClearIcon {
    padding: 0px !important;
  }
  .MuiAutocomplete-root {
    padding: 0px !important;
  }
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  .MuiAutocomplete-input {
    padding: 0px 4px 0px 6px !important;
  }
  .MuiTextField-root {
    height: 30px !important;
  }
  .MuiAutocomplete-popper {
    background: rgb(255, 255, 255) !important;
  }
  .Mui-focused {
    background: #fff !important;
    z-index: 2;
  }
}
.read_text {
  color: #1976d2;
  cursor: pointer;
}
//gaps
.gap_10 {
  gap: 10px;
}
.gap_30 {
  gap: 30px;
}

//border radius
.br_5 {
  border-radius: 5px;
}
.br_10 {
  border-radius: 10px;
}
.border_1{
  border: 1px solid;
}
.theme_bg {
  background-color: #531c4c;
}

.min_height_500{
min-height: 500px !important;
}

.tds_gst_table{
  width: 100% ;
  border-collapse: collapse;
}


