.select_ui {
  padding: 10px;
  margin-left: 15px;
  border-radius: 6px;
  border-color: gray;
  min-height: 25px;

  input {
    padding: 10px;
    border-radius: 6px;
    border-color: gray;
    min-height: 25px;
    max-height: 25px;
  }
}

button.config_btn {
  font-size: 30px;
  margin-left: 10px;
  color: white;
}

.tallyVoucher_table {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin-top: 25px !important;
  width: 100% !important;
}
.m_25 {
  margin: 25px !important;
}
.tallyVoucher_submit_btn {
  width: 100px !important;
  height: 40px !important;
  margin: auto 0 !important;
  margin-left: 20px !important;
}
.tallyVoucher_card {
  padding: 20px 40px !important;
  align-items: center !important;
}
.pb_40 {
  padding-bottom: 40px !important;
}
.tallyVoucher_businessPartnerId {
  margin: 0 40px !important;
}

.cursor-pointer {
  transition: all 0.2s ease-out;
  border-radius: 12px;
  border: 0.4px solid #b4bfcf;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-out;
  appearance: initial;
  background-color: #fff;
  cursor: pointer;
}

th .cursor-pointer{
  border: 1px solid #fff;
}

.checkbox_label{
  position: relative;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  
  &:hover .cursor-pointer{
    background-color: #e2e4e8;
  }
}

.checkbox_icon {
  position: absolute;
  height: 8px;
  fill: white;
  top: 6px;
  left: 5px;

  &.indeterminate{
    fill: #541c4c;
    height: 4px;
    top: 8px;
    width: 10px;
    display: block;
    background: #541c4c;
    border-radius: 10px;
  }
}
.cursor-pointer:checked {
  opacity: 1;
  background-color: #541c4c !important;
}

tr:hover .cursor-pointer {
  opacity: 1;
}

.bold {
  font-weight: 600;
  font-size: inherit;
}
.selectAllBtn {
  position: relative;
}
.selectAllBtn:disabled:hover::before {
  content: " ";
  position: absolute;
  left: calc(100% - 5px);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #444;
}
.selectAllBtn:disabled:hover::after {
  content: "Filter Less than 10 Rows to Select All";
  position: absolute;
  font-size: 10px;
  left: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 10;
  max-width: 500px;
  background: #444;
  color: #fff;
  animation: fade-in 0.25s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  50% {
    opacity: 0.75;
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
