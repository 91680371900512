.status_theme_btn{
   box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
   border-radius: 6px !important;
   font-size: 14px !important;
   text-transform: none !important;
   padding: 1px 12px !important;
   }

.loading_gif{
   transform: scale(0.7);
   height: 25px !important;
}

.loading-active {
   animation: rotate 1.25s ease 0.25s infinite reverse;
}
.white-progress {
   color: white !important;
 }

@keyframes rotate {
   from { transform: rotate(0)}
   to { transform: rotate(360deg)}
}
