.highlight_column {
  color: wheat;
}

.category_ui {
  .category_autocomplete {
    &,
    & div {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    & fieldset {
      border-width: 0.4px;
      border-radius: 12px;
    }
    & .MuiChip-root {
      border-radius: 12px;
      margin-top: 4px;
    }
  }
}

.LegendUL {
  display: grid !important;
  grid-template-columns: repeat(4, auto) !important;
  gap: 8px;
  padding: 12px 4px;

  li {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  span {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  p {
    display: inline;
    font-size: 13px;
  }
}
