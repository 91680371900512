.email_text {
  font-size: 18px;
  color: black;
  p {
    padding: 3px;
  }
}
.email_dialog_footer_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.email_width {
  width: 33%;
  margin-top: 10px;
  margin-bottom: 10px;

  & + & {
    margin-left: 10px;
  }
}

.Email_Field {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
