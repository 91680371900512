/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  cursor: pointer;
}
.transformer_formcontrol {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  .transformer_input {
    .MuiFormHelperText-root {
      position: absolute;
      bottom: -10px;
      z-index: 99;
      background: white;
      padding: 4px;
    }
  }
}
.dropdown1 {
  background-color: #f9f9f9;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 7%);
  padding: 12px 16px;
  z-index: 1;
  min-width: 300px;
  border-radius: 6px;
}
.dropdown_Tally {
  background-color: #f9f9f9;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 7%);
  padding: 12px 16px;
  z-index: 1;
  min-width: 200px;
  border-radius: 6px;
}

/* responsive for specific width  */

.download_test_canvas {
  position: fixed !important;
  right: 5px;
  bottom: 10px;
  height: 60px;
  width: 200px;
  opacity: 0;
  z-index: 999;
}
.details_page {
  padding-top: 1vw;
  padding-left: 2vw;
  padding-bottom: 2vw;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.open_btn {
  background-color: #27b27c !important;
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  text-transform: none !important;
  color: white !important;
  font-size: 13px !important;
  width: 70px !important;
}
.openBtnPadding {
  padding: 0;
  text-align: center !important;
}
.addition_input_row {
  display: inline-flex;
  grid-row-gap: 10px;
  gap: 10px;
  flex-flow: row;
  select {
    border-radius: 3px;
    padding: 10px 5px;
    border-color: #8080806e;
    min-height: 40px !important;
    margin-right: 0;
  }
  .config_input {
    input {
      padding: 10px 10px !important;
    }
  }
}
.config_form {
  justify-content: flex-start;
  .label_head {
    min-width: 290px;
  }
}

.multi_row {
  .config_field_row {
    display: flex;
    margin-bottom: 5px;
    gap: 15px;
  }
}

button.minus_btn,
button.plus_btn {
  padding: 5px 10px;
  border: 1px solid #8080806e;
  background: transparent;
  border-radius: 4px;
  color: black;
  font-size: 20px;
  font-weight: 600;
  min-width: 40px;
}

.next_form_field {
  display: flex;
  gap: 20px;
  // justify-content: center;
  justify-content: space-between;
  align-items: flex-start;
}

.addition_row {
  display: flex;
  justify-content: center;
  flex-flow: column;
  gap: 10px;
  .col {
    display: flex;
    gap: 10px;
  }
}

.transformer_input {
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -10px;
    z-index: 99;
    background: white;
    padding: 4px;
  }
}

.label_head {
  width: 200px;
  text-align: left;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  color: white;

  display: flex;
  padding: 0px 10px;
  border-radius: 4px;
}

.business_content {
  display: inline-flex;
  flex-flow: row;
  // justify-content: flex-end;
  // justify-content: space-between;
  align-items: center;
  padding-left: 40px !important;
  width: 100%;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 20px;
    font-weight: 600;
    padding: 12px 20px 12px 0px;
  }
  .width_50_per {
    width: 50%;
  }
  .width_25_per {
    width: 25%;
  }
  .width_75_per {
    width: 75%;
    justify-content: left;
  }
  .width_33_per {
    width: 33%;
  }
}
.main_row {
  flex-flow: column !important;
  justify-content: center;
  align-items: center;
}
.date_field > div {
  min-width: 300px !important;
  max-width: 100%;
}

.rules_window {
  display: inline-block;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column !important;
}
.sanitizer_popup {
  z-index: 999 !important;
  .MuiDialog-paperFullScreen {
    max-width: 1050px;
    top: 70px;
    left: 100px;
    z-index: 100 !important;
    padding-bottom: 100px;
  }
}

.temp_data_row {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
  .temp_name {
    width: 100%;
    min-width: 200px;
    .control {
      #templateNameError {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        font-size: 14px;
      }
    }
  }
  .select_ui {
    min-height: 40px;
    margin: 0;
    margin-right: 10px;
  }
}
#businessPartner-listbox {
  .MuiAutocomplete-groupLabel {
    line-height: 21px !important;
    padding-left: 10px !important;
    font-weight: 700 !important;
  }
}

.borderRight {
  border-right: 1px solid #53526c;
}
.borderLeft {
  border-left: 1px solid #53526c;
}

table .borderLeft,
table .borderRight {
  border: none;
}

.top_border_radius {
  border-radius: 20px 20px 0 0;
}
.bottom_border_radius {
  border-radius: 0 0 20px 20px;
}
.box_height {
  height: 320px;
}
.date_text {
  font-size: 12px;
  font-weight: 600;
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  /* STYLES HERE */
  .closingblc_input,
  .date_field > div {
    min-width: 300px !important;
  }
}

/* mobile view style HERE */

@media screen and (min-width: 320px) and (max-width: 767px) {
  #summary_page {
    & > div {
      justify-content: center !important;
      .control {
        text-align: center;
      }
    }
  }
  /* STYLES HERE */
  .main_row {
    flex-flow: column !important;
    & > div {
      margin-bottom: 20px !important;
      justify-content: center !important;
    }
  }
  .date_row {
    flex-flow: column-reverse !important;
  }

  .closingblc_input,
  .date_field > div {
    min-width: 300px !important;
    max-width: 100%;
  }
}
.errorPastDate_modal_text {
  font-size: 20px;
  font-family: "Roboto";
  padding: auto;
  padding: auto;
  padding-left: 10px;
}
.no_ledger_entries {
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
}
.date_error_text {
  color: red;
  padding-bottom: 20px;
}
.dispute_btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.adjust_entry_btn {
  background-color: #21b47d !important;
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  font-size: 12px !important;
  color: #fff !important;
  min-width: 70px !important;
}
.raise_dispute_btn {
  background-color: #d6235c !important;
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border-radius: 6px !important;
  font-size: 12px !important;
  color: #fff !important;
  min-width: 70px !important;
}

.column_header_name {
  width: 50%;
  display: flex;
  align-items: center;
  background-color: #8080802b;
  height: 40px;
  padding-left: 20px;
  border-radius: 4px;
}
.column_header_dropdown {
  width: 45%;
  .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}
.documentType_name {
  // width: 30%;
  display: flex;
  align-items: center;
  background-color: #8080802b;
  height: 40px;
  padding-left: 20px;
  border-radius: 20px;
}
.documentType_count {
  width: 10%;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 20px;
}
.unassigned_documentType_table_container {
  max-height: 400px;
  overflow-y: scroll;
}
.unassigned_documentType_table {
  border-collapse: collapse;
  width: 100%;
}

.unassigned_documentType_table th,
.unassigned_documentType_table td {
  padding: 10px;
  border: 1px solid lightgray;
  text-align: center !important;
}
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

// .unassigned_documentType_table th:not(:last-child),
// .unassigned_documentType_table td:not(:last-child) {
//   border-right: 1px solid black;
// }

.documentType_dropdown_div {
  display: flex;
  align-items: center;
  width: 100%;
  .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}

.documentType_select {
  width: 200px;
  margin-right: 20px;
}

.documentType_advanced_settings {
  cursor: pointer;
}

.entries_missing {
  color: red;
}
.transactionType_dropdown {
  width: 25%;
  .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}
.config_dialog_title {
  width: 80%;
  line-height: 1.4;
  font-size: 20px;
}
.config_add_btn {
  // background: #531C4C;
  // color: #FFFFFF;

  // background: #d7d7d7;
  // color: black;
  background: white;
  color: #531c4c;
  // background: #ecece3;
  // color: black;

  // background: #EFEFEF;
  // color: black;

  // background: #21b47d;
  // color: #FFFFFF;

  border-radius: 999px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 6px 18px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 1px solid gray;
}
.config_tags_div {
  margin-top: 10px;
  padding-bottom: 20px;
  // border-bottom: 1px solid gainsboro;
}
.config_tags_div_docType {
  margin-top: 5px;
  padding-bottom: 5px;
  // border-bottom: 1px solid gainsboro;
}
.config_tags {
  // background: #d7d7d7;
  background: #efefef;
  border-radius: 999px;
  box-sizing: border-box;
  color: black;
  // cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 2px 8px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
  margin: 5px 2px;
  display: inline-flex;
  align-items: center;
}
.config_delete {
  margin-left: 5px;
  height: 15px !important;
  max-height: 15px !important;
  max-width: 15px !important;
  min-height: 15px !important;
  min-width: 15px !important;
  width: 15px !important;
}
.error_text_config {
  color: red;
  font-size: 14px;
}
.error_border_config {
  border: 1px solid red;
}
.note_config {
  font-size: 18px;
  font-family: "Roboto";
  // padding-bottom: 15px;
}
.recon_segment {
  margin-top: 25px;
  border: 1px solid black;
  padding-left: 10px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 15px;
  cursor: pointer;
  background: #531c4c;
  color: white;
}
.up_down_arrow {
  font-size: 50px !important;
}
.date_filter_btn {
  box-shadow: 0px 1px 8px 0px rgb(60 59 59 / 11%);
  border: 1px solid !important;
  border-radius: 20px !important;
  font-size: 14px !important;
  text-transform: none !important;
  background-color: #fff !important;
  color: #363636 !important;
  padding: 4px 16px !important;
}
.withSanitizer {
  color: #27b27c !important;
}
.withoutSanitizer {
  color: white !important;
}
.unassignedAdvanceDocTypeName {
  width: 200px !important;
  padding: 6px;
  background: #531c4c;
  color: white;
  font-weight: 600;
}

.transacation_type {
  font-weight: 600;
  font-variant: all-petite-caps;
  user-select: none;
}

.all_row,
.debit_row,
.credit_row {
  padding: 0px 4px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 12px;
  min-height: inherit;
}

.drop-zone {
  position: relative;
  min-height: 30px;
  transition: all 0.25s ease-out;

  &:has(.active) {
    min-height: 100px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
  .drop-area {
    z-index: 10;
    position: absolute;
    height: 0%;
    opacity: 0.9;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out;

    &.active {
      height: max(100%, 100px);
      opacity: 0.97;
      backdrop-filter: blur(8px);

      svg.cls_1 {
        height: 80px;
        width: 80px;
      }
    }

    svg.cls_1 {
      height: 0px;
      width: 0px;

      line,
      path {
        fill: none;
        stroke: #777;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
      }
    }
  }
}

::selection {
  background-color: hsl(212 89% 86%);
}
.as_on_text {
  font-size: 12px;
}

.zIndex_10 {
  z-index: 10 !important;
}

.maxWidth_1000 {
  max-width: 1000px !important;
}
