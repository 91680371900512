.UploadedSummary{
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
      }
      
      td, th {
        border: 1px solid #000;
        text-align: left;
        padding: 8px;
      }
}
.configMapHeader{
  padding: 10px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}