table tr:nth-child(even) {
  font-family: "WorkSans";
  background-color: #ffffff;
}

table tr:nth-child(odd) {
  font-family: "WorkSans";
  background-color: #eaf0f9;
}

// 1st Phase Rules Table.main-table , table-head, table-head column, sticky left-right.
// 2nd Phase Rules Non Macintosh Devices Custom Scrollbar 
// 3rd Phase Rules Table Head, sort-icon, filterBox

table.main-table {
  display: flex;
  flex-flow: column;
  height: calc(100% - 50px);
  overflow: auto;
  max-height: 70vh;
  position: relative;

  .column {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0.75rem;
    white-space: nowrap;
  }

  .table-head {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .stickyLeft,
  .stickyRight {
    position: sticky;
    left: var(--data-val, 0);
    z-index: 2;

  }

  .stickyRight {
    left: unset;
    right: var(--data-val, 0);
  }

  td.stickyLeft,
  td.stickyRight {
    background-color: inherit;
  }

  .blankFolder{
    height: 4em;
    width: 4em;
    color: #8f9eb4;
    transform: scale(1.3);
  }
}

.NonMac {
  & :not(.NonMacBody)::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  & :not(.NonMacBody)::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #d5dce3;
    border: 1px solid transparent;
    background-clip: content-box;
  }

  & :not(.NonMacBody)::-webkit-scrollbar-track {
    background: transparent;
  }
}
// table tr:hover {
//   background-color: #f5f9fc;
// }
.table_select_ui {
  padding: 10px;
  margin-left: 15px;
  border-radius: 6px;
  border-color: gray;
  min-height: 25px;
  input {
    padding: 10px;
    border-radius: 6px;
    border-color: gray;
    min-height: 25px;
    max-height: 25px;
  }
}

svg {
  transition: all 0.25s ease-out;
}

.rotated {
  transform: rotate(180deg);
}

table th {
  font-family: "WorkSans";
  text-align: center;
  background-color: #531c4c;
  color: white;
  padding: 12px 10px 12px 0px;
  border-bottom: 1px solid #53526c;
  overflow-wrap: break-word;
  position: relative;
  cursor: pointer;

  &:has(.noSort), &.noSort {
    cursor: default;
  }

  .header .sort-icon {
    opacity: 0;
    transition: all 0.2s ease-out;
  }

  &:hover .sort-icon, .header:hover .sort-icon {
    opacity: 1;
  }

  &:hover .filterBox {
    opacity: 1;
  }

  .filterBox:has(input.focused, input.shown) {
    opacity: 1;
  }

  .filterBox svg path {
    transition: all 0.5s ease-out;
  }
  .filterBox:has(input.focused) svg path {
    fill: #4db6ac;
  }

  .filterBox {
    position: absolute;
    bottom: -15px;
    background: #fafaff;
    padding: 0px 8px;
    width: calc(100% - 10px);
    border-radius: 12px;
    opacity: 0;
    z-index: 2;
    transition: all 0.2s ease-out;
    box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.3);
  }
  .filterBox span {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 4px;
    padding: 2px;
  }

  .filterBox input {
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
  }
}

table td {
  padding: 11px;
  font-family: "WorkSans";
  text-align: center;
  color: black;
  overflow-wrap: break-word;
}
// .next_prevbtn{
//   color: #27b27c !important;
// }
.summary_wrapper {
  padding: 20px;
  overflow: initial !important;
  overflow-x: scroll !important;

  .summary_table tbody > tr {
    align-items: center;
    .table_subrow {
      padding-left: 30px;
    }
  }
  .summary_table_footer {
    padding-left: 10px;
    margin-top: 15px;
    text-align: center;
  }
}
.pdfTableHeading {
  padding: 10px 40px;
  height: 40px;
  border-left: 0.1px solid grey;
  border-right: 0.1px solid grey;
}
.pdfTableHeading_border {
  border: 0.1px solid gray;
}
.errorMessage {
  color: black !important;
  background-color: white !important;
  border-left: 0.1px solid grey;
  border-right: 0.1px solid grey;
  padding: 10px;
  font-weight: 400;
}
.table_fixed_div {
  overflow-x: scroll;
  overflow-y: scroll;
  height: fit-content;
  max-height: 55vh;
  table thead {
    position: sticky;
    top: 0px;
    z-index: 2;
  }
}
