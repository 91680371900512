.grouped_row{
  border-left: 2px solid #41b37c;
  border-right: 2px solid #41b37c;
  border-top: 2px solid #41b37c;
  display: flex;
  .cell{
    padding: 0px;
    font-size: 14px;
    font-weight: 600;

  }
  button{
    display: none;
    padding: 0;
  }
}

.normal_row {
    border-left: 2px solid #41b37c;
    border-right: 2px solid #41b37c;
    td{
      text-align: center !important;
    }
    display: flex;
}
.normal_row:last-child,.grouped_row:last-child{
  border-bottom: 2px solid #41b37c;
}
.detail_column_head{
  text-align: center;
  border-right: 1px solid #ccc;
}
.detail_column_head:last-child{
  border: none;
}


